<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack()">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10065')}} &middot; {{t('10066')}} 
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub event">
			<!-- [D] 활성화된 tab에 active 클래스 추가 -->
			<section class="listcartegory">
				<div class="box">
					<div class="tab tab--half">
						<a @click="eventTab = 0" :class="{'active' : eventTab === 0}">{{t('10065')}} </a>
						<a @click="eventTab = 1" :class="{'active' : eventTab === 1}">{{t('10066')}} </a>
					</div>
				</div>
			</section>
			<!-- content -->
			<section class="content" v-if="selectList.length > 0">
				<div class="listbox listbox--first" v-if="eventTab === 0">
					<!-- [REST] 숙소 리스트 -->
					<ul class="list list--full">
						<li v-for="item in selectList" :key="item.PROMO_NO">
							<div class="item">
								<router-link :to="'/event/event_detail/' + item.EV_NO" class="list__link">
									<div class="list__wrap">
										<div class="list__image">
											<div class="list__imagebox noneimage">
												<div class="imagebg" :style="{'background-image': 'url(\'' + item.EVENT_PIC1 + '\')'}"></div>
											</div>
										</div>
										<div class="list__data">
											<h3 class="list__title info-title" style="display:inline-block">
												{{item.EV_NM}}
											</h3>
										</div>
										<div class="list__date grey1">
											{{dayjs(item.SDATE).format("YYYY.MM.DD")}} ~ {{dayjs(item.EDATE).format("YYYY.MM.DD")}}
										</div>
									</div>
								</router-link>
							</div>
						</li>
					</ul>
				</div>
				<div class="listbox listbox--first" v-else-if="eventTab === 1">
					<!-- [REST] 숙소 리스트 -->
					<ul class="list list--full">
						<li v-for="item in selectList" :key="item.PROMO_NO">
							<div class="item">
								<router-link :to="`/event/${item.PROMO_NO}`" class="list__link">
									<div class="list__wrap">
										<div class="list__image">
											<div class="list__imagebox noneimage">
												<div class="imagebg" :style="{'background-image': 'url(\'' + item.IMG_URL + '\')'}"></div>
											</div>
										</div>
										<div class="list__data">
											<h3 class="list__title info-title" style="display:inline-block">
												{{item.PROMO_NM}}
											</h3>
										</div>
										<div class="list__date grey1">
											{{dayjs(item.SDATE).format("YYYY.MM.DD")}} ~ {{dayjs(item.EDATE).format("YYYY.MM.DD")}}
										</div>
									</div>
								</router-link>
							</div>
						</li>
					</ul>
				</div>
			</section>
			<section class="content" v-else>
				<div class="centered centered--large" style="height:calc(100vh - 175px)">
					<div class="centered__wrap">
						<div class="textbox">
							<div class="textbox__icon">
								<img src="@/assets/images/img_noevent.png" alt="현재 진행중인 이벤트가 없어요" style="width: 64px;">
							</div>
							<h3 class="textbox__title" v-if="eventTab === 0">{{t('10067')}}</h3>
							<h3 class="textbox__title" v-else>{{t('10068')}}</h3>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted, ref } from "vue"
import { useRouter, useRoute } from 'vue-router';
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const { t }= useI18n() //번역필수 모듈

    const allpromoList = computed(() => store.state.mrm01.alleventList);
    const alleventList = computed(() => store.state.mev01.mev0104);
    const eventList = computed(() => store.state.mrm01.eventList);
    const planList = computed(() => store.state.mrm01.planList);
    const selectList = computed(() => eventTab.value === 0 ? alleventList.value : allpromoList.value);

		const eventTab = ref(0);

		const goBack = () => {
      router.go(-1);
		};

		onMounted(()=>{
			eventTab.value = Number(route.query["type"] ?? 0);
			store.dispatch("mrm01/fetchallEventList");
			store.dispatch("mrm01/fetchEventList");
			store.dispatch("mev01/fetchMev0104",{
				proc_cd: "00",
				evNo: ""
			});
			store.dispatch("mrm01/fetchPlanList");
		});

		return {
			dayjs,
			goBack,
			eventList,
			allpromoList,
			alleventList,
			planList,
			eventTab,
			selectList,
			t,
			i18n,
		}
	},
}
</script>